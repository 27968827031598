import { font, margin, padding } from '@prototyp/gatsby-plugin-gumball/utils';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import {
  ActionModal,
  AsyncSelectField,
  Button,
  Heading,
  InputField,
  SelectComponent,
} from 'modules/app';
import { AppState } from 'modules/redux-store';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { PlanFormData } from '..';
import { useAdminPlans, useFormModal } from '../hooks';

interface Props {
  handleModalClose: VoidFunction;
  handleAddPlan?: (
    data: CreatePlanRequest,
    handleModalClose: VoidFunction,
    handleSetErrorMessage: (errorMessage?: string) => void,
  ) => Promise<void>;
  isOpen: boolean;
  plan?: Plan;
  isEdit?: boolean;
  isCustomPlan?: boolean;
  options?: {
    title: string;
    description: string;
    featuresTitle?: string;
  };
}

export const FormModal: React.FC<Props> = ({
  isOpen,
  handleModalClose,
  handleAddPlan,
  isCustomPlan,
  plan,
  options,
  isEdit,
}) => {
  const {
    data: { companies },
  } = useSelector((state: AppState) => state.adminUsers);
  const { formatMessage } = useIntl();

  const { searchCompanies } = useAdminPlans();
  const { errorMessage, handleFormSubmit, handleFormClose, companyValues } =
    useFormModal({
      handleModalClose,
      handleAddPlan,
      plan,
      isEdit,
    });

  const methods = useForm<PlanFormData>({
    defaultValues: {
      allowedActiveOpeningCount: String(plan?.allowedActiveOpeningCount) || '',
      price: String(plan?.price) || '',
      name: plan?.name || '',
      currency: plan?.currency || 'EUR',
      companyIds: plan?.assignedCompanies.map((c) => ({
        label: c.name,
        value: c.id,
      })),
    },
  });
  const { handleSubmit } = methods;

  if (!isOpen) return null;

  return (
    <ActionModal isOpen={isOpen} setIsOpen={handleModalClose}>
      <header>
        <Heading as="h1" size="xLarge" css={margin.bottom.lrg}>
          <FormattedMessage id={options?.title} />
        </Heading>
        <p>
          <FormattedMessage id={options?.description} />
        </p>
      </header>

      <section css={[padding.top.huge, padding.bottom.huge]}>
        <FormProvider {...methods}>
          <form id="planForm" onSubmit={handleSubmit(handleFormSubmit)}>
            <InputField
              id="name"
              name="name"
              label="adminPlans.modal.inputNameLabel"
              value={plan?.name || ''}
              constraints={{ required: true, maxLength: 100 }}
            />
            {isCustomPlan && (
              <AsyncSelectField
                name="companyIds"
                options={companies?.data.map((c) => ({
                  label: c.name,
                  value: c.id,
                }))}
                label="Company that will have this custom plan"
                placeholder="Search company"
                onSearch={searchCompanies}
                isMulti
                defaultValue={companyValues}
                constraints={{ required: true }}
              />
            )}

            <InputField
              id="price"
              name="price"
              label="adminPlans.modal.inputPriceLabel"
              value={plan ? String(plan.price) : ''}
              constraints={{
                required: true,
                maxLength: 10,
                pattern: {
                  value: /^[0-9.,]*$/g,
                  message: formatMessage({ id: 'shared.errors.number' }),
                },
              }}
            />

            <SelectComponent
              name="currency"
              label="Currency"
              isDisabled={isEdit}
              values={[
                { label: 'EUR', value: 'EUR' },
                { label: 'USD', value: 'USD' },
              ]}
              selectedValue={
                plan
                  ? {
                      label: plan.currency,
                      value: plan.currency,
                    }
                  : { label: 'EUR', value: 'EUR' }
              }
              constraints={{ required: true }}
            />

            <InputField
              id="allowedActiveOpeningCount"
              name="allowedActiveOpeningCount"
              label="adminPlans.modal.inputAllowedActiveOpeningsLabel"
              value={plan ? String(plan.allowedActiveOpeningCount) : ''}
              constraints={{
                required: true,
                maxLength: 10,
                pattern: {
                  value: /^[0-9.,]*$/g,
                  message: formatMessage({ id: 'shared.errors.number' }),
                },
              }}
            />
          </form>
        </FormProvider>
        {errorMessage && <p css={font.weight.bold}>{errorMessage}</p>}
      </section>

      <footer>
        <Button
          form="planForm"
          buttonType="primary"
          size="small"
          type="submit"
          textId={
            !plan && isCustomPlan ? 'buttons.save.plans.custom' : 'buttons.save'
          }
        />

        <Button
          buttonType="ghost"
          size="small"
          type="button"
          textId="buttons.cancel"
          onClick={handleFormClose}
        />
      </footer>
    </ActionModal>
  );
};
