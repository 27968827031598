import { useEffect, useRef, useState } from 'react';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';

export const useChartRef = (values?: number[]) => {
  const chartRef =
    useRef<ChartJSOrUndefined<'line', number[] | undefined, string>>();
  const [gradient, setGradient] = useState<CanvasGradient>();
  useEffect(() => {
    const newGradient = chartRef.current?.ctx?.createLinearGradient(
      0,
      0,
      0,
      200,
    );
    newGradient?.addColorStop(0, 'rgb(138,234,221, 0.2)');
    newGradient?.addColorStop(1, 'rgb(249,250,251, 0.2)');
    setGradient(newGradient);
  }, []);

  useEffect(() => {
    if (!gradient || !chartRef.current) return;

    chartRef.current.data.datasets[0].backgroundColor = gradient;
    chartRef.current?.update();
  }, [gradient, values]);

  return {
    chartRef,
  };
};
