import { margin, padding } from '@prototyp/gatsby-plugin-gumball/utils';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { ActionModal, Button, Heading } from 'modules/app';
import React from 'react';

import * as plansItem from '../styles/PlansItem.styles';

interface Props {
  handleWarningModalClose: VoidFunction;
  isOpen: boolean;
  plan: Plan;
  options: WarningModalOptions;
}

export const WarningModal: React.FC<Props> = ({
  isOpen,
  handleWarningModalClose,
  plan,
  options,
}) => {
  if (!isOpen) return null;

  return (
    <ActionModal isOpen={isOpen} setIsOpen={handleWarningModalClose}>
      <header>
        <Heading as="h1" size="xLarge" css={margin.bottom.lrg}>
          <FormattedMessage id={options.title} />
        </Heading>
        <p>
          <FormattedMessage id={options.description} />
        </p>
      </header>

      <section css={[padding.top.huge, padding.bottom.huge]}>
        <div role="presentation" css={plansItem.footerItem}>
          <p css={plansItem.itemLabel}>
            <FormattedMessage id="adminPlans.modal.planName" />:
          </p>
          <p css={plansItem.itemValue}>{plan.name}</p>
        </div>

        <div role="presentation" css={plansItem.footerItem}>
          <p css={plansItem.itemLabel}>
            <FormattedMessage id="adminPlans.modal.planPrice" />:
          </p>
          <p css={plansItem.itemValue}>{plan.price}</p>
        </div>

        <div role="presentation" css={plansItem.footerItem}>
          <p css={plansItem.itemLabel}>
            <FormattedMessage id="adminPlans.modal.planCurrency" />:
          </p>
          <p css={plansItem.itemValue}>{plan.currency}</p>
        </div>

        <div role="presentation" css={plansItem.footerItem}>
          <p css={plansItem.itemLabel}>
            <FormattedMessage id="adminPlans.modal.planAllowedOpenings" />:
          </p>
          <p css={plansItem.itemValue}>{plan.allowedActiveOpeningCount}</p>
        </div>

        {plan.planType === 'custom' && !!plan.activeCompanies.length && (
          <div role="presentation">
            <p css={plansItem.itemLabel}>
              <FormattedMessage id="adminPlans.modal.planAssignedCompanies" />:
            </p>
            <ul css={plansItem.companyList}>
              {plan.assignedCompanies.map((company) => (
                <li key={company.id}>{company.name}</li>
              ))}
            </ul>
          </div>
        )}
        {plan.planType === 'custom' && !!plan.assignedCompanies.length && (
          <div role="presentation">
            <p>
              <FormattedMessage id="adminPlans.modal.planActiveCompanies" />:
            </p>
            <ul css={plansItem.companyList}>
              {plan.activeCompanies.map((company) => (
                <li key={company.id}>{company.name}</li>
              ))}
            </ul>
          </div>
        )}
      </section>

      <footer>
        <Button
          form="addPlanForm"
          buttonType="primary"
          size="small"
          type="submit"
          textId={options.primaryBtnLabel}
          onClick={options.handleFunction}
        />

        <Button
          buttonType="ghost"
          size="small"
          type="button"
          textId="buttons.cancel"
          onClick={handleWarningModalClose}
        />
      </footer>
    </ActionModal>
  );
};
