import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';

export const grid = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--spacing-xxxlrg);
  row-gap: var(--spacing-huge);
`;

export const heading = css`
  padding: 0;
`;

export const dashboardWrapper = css`
  max-width: 40rem;
  padding-block: calc(var(--unit) * 20);
  margin-left: ${spacing(370)};
`;

export const dashboardSection = css`
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-huge);
  margin-bottom: calc(var(--unit) * 25);
`;

export const loading = css`
  width: 100%;
  height: 180px;
  min-height: 180px;
  border-radius: 4px;
`;
