import React from 'react';
import { Link } from 'gatsby-plugin-intl';
import { motion } from 'framer-motion';
import { useMatchUrl } from 'modules/admin/plans';

import { FADE_IN_MOVE_Y_REGULAR } from '../../../../style/animations.config';
import * as styles from 'modules/admin/styles/Sidebar.styles';

export const PlansSidebar: React.FC = () => {
  const { matchBase, matchRegular, matchCustom, matchFree } = useMatchUrl();

  return (
    <div css={[styles.sidebar, styles.subSidebar]}>
      <div css={styles.stickyNavigation}>
        <div css={styles.sidebar__navigation}>
          <motion.section {...FADE_IN_MOVE_Y_REGULAR}>
            <Link
              to="/admin/plans/regular"
              className={matchRegular || matchBase ? 'active' : ''}
            >
              Regular plans
            </Link>
            <Link
              to="/admin/plans/custom"
              className={matchCustom ? 'active' : ''}
            >
              Custom plans
            </Link>
            <Link to="/admin/plans/free" className={matchFree ? 'active' : ''}>
              Free plans
            </Link>
          </motion.section>
        </div>
      </div>
    </div>
  );
};
