import { margin } from '@prototyp/gatsby-plugin-gumball/utils';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import {
  ActionModal,
  AsyncSelectField,
  Button,
  Heading,
  InputField,
} from 'modules/app';
import { AppState } from 'modules/redux-store';
import React from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { useAdminPlans, useFormModal } from '../hooks';
import { PlanFormData } from '../helpers';

interface Props {
  handleModalClose: VoidFunction;
  isOpen: boolean;
  plan: Plan;
  options?: {
    title: string;
    description: string;
    isActivate?: boolean;
  };
}

interface FormData {
  companyIds: SelectableNameStructure[];
  company: SelectableNameStructure;
  durationInMonths: string;
}

const mapCompanies = (c: Companies) => ({ value: c.id, label: c.name });

export const AssignToCompanyFormModal: React.FC<Props> = ({
  handleModalClose,
  isOpen,
  plan,
  options,
}) => {
  const { searchCompanies, assignPlan } = useAdminPlans();
  const { formatMessage } = useIntl();
  const {
    data: { companies },
  } = useSelector((state: AppState) => state.adminUsers);

  const { handleFormSubmit } = useFormModal({
    handleModalClose,
    plan,
    isEdit: true,
  });

  const form = useForm<FormData>({
    defaultValues: {
      companyIds: plan.assignedCompanies.map((c) => ({
        label: c.name,
        value: c.id,
      })),
    },
  });

  const handleSubmit = (data: FormData) => {
    if (!options?.isActivate) {
      const newPlan: PlanFormData = {
        allowedActiveOpeningCount: String(plan.allowedActiveOpeningCount),
        currency: plan.currency,
        name: plan.name,
        price: String(plan.price),
        companyIds: data.companyIds,
      };
      handleFormSubmit(newPlan);
      return;
    }

    assignPlan(
      {
        company: data.company,
        durationInMonths: Number(data.durationInMonths),
        targetPlanId: plan.id,
      },
      handleModalClose,
    );
  };

  const freeValues = companies?.data
    .filter((c) => c.planStatus !== 'active')
    .map(mapCompanies);

  return (
    <ActionModal isOpen={isOpen} setIsOpen={handleModalClose}>
      <header>
        <Heading as="h1" size="xLarge" css={margin.bottom.lrg}>
          <FormattedMessage id={options?.title} />
        </Heading>
        <p css={margin.bottom.lrg}>
          <FormattedMessage id={options?.description} />
        </p>
      </header>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <section>
            <AsyncSelectField
              name={options?.isActivate ? 'company' : 'companyIds'}
              options={freeValues}
              label={`Company that will have this ${
                plan.planType === 'custom' ? 'custom' : 'free'
              } plan`}
              placeholder="Search company"
              onSearch={searchCompanies}
              isMulti={!options?.isActivate}
            />

            {options?.isActivate && plan.name !== 'Free trial' && (
              <InputField
                id="durationInMonths"
                name="durationInMonths"
                label="Duration in months"
                value={''}
                constraints={{
                  required: true,
                  maxLength: 10,
                  pattern: {
                    value: /^[0-9.,]*$/g,
                    message: formatMessage({ id: 'shared.errors.number' }),
                  },
                }}
              />
            )}
          </section>
          <footer>
            <Button
              buttonType="primary"
              size="small"
              type="submit"
              textId={
                options?.isActivate
                  ? 'adminPlans.companyActivate.btn'
                  : 'buttons.assignPlan'
              }
            />

            <Button
              buttonType="ghost"
              size="small"
              type="button"
              textId="buttons.cancel"
              onClick={handleModalClose}
            />
          </footer>
        </form>
      </FormProvider>
    </ActionModal>
  );
};
