const mapperUsers = ({
  firstName: name,
  email: info,
  createdAt: time,
  roleRoleId: role,
  profilePicture: picture,
  ...rest
}: Users) =>
  ({
    ...rest,
    name,
    info,
    time,
    role,
    picture,
    type: 'users',
  } as Users & RenamedProps);

const mapperTalents = ({
  firstName: name,
  email: info,
  createdAt: time,
  roleRoleId: role,
  profilePicture: picture,
  ...rest
}: Users) =>
  ({
    ...rest,
    name,
    info,
    time,
    role,
    picture,
    type: 'users',
  } as Users & RenamedProps);

const mapperCompanies = ({
  updatedAt: time,
  handle: info,
  iconPicture: picture,
  ...rest
}: Companies) =>
  ({
    ...rest,
    time,
    info,
    picture,
    type: 'companies',
  } as Companies & RenamedProps);

const mapperAdmin = ({
  firstName: name,
  email: info,
  createdAt: time,
  roleRoleId: role,
  profilePicture: picture,
  ...rest
}: Users) =>
  ({
    ...rest,
    name,
    info,
    time,
    role,
    picture,
    type: 'admin',
  } as Users & RenamedProps);

const mapperSupport = ({
  firstName: name,
  email: info,
  createdAt: time,
  roleRoleId: role,
  profilePicture: picture,
  ...rest
}: Users) =>
  ({
    ...rest,
    name,
    info,
    time,
    role,
    picture,
    type: 'support',
  } as Users & RenamedProps);

const mapperSessions = ({
  userFirstName: name,
  userEmail: info,
  updatedAt: time,
  adminRole: role,
  userLastName: lastName,
  ...rest
}: Sessions) =>
  ({
    ...rest,
    name,
    info,
    time,
    role,
    lastName,
    type: 'sessions',
  } as Sessions & RenamedProps);

export const mappers = {
  users: mapperUsers,
  talents: mapperTalents,
  admin: mapperAdmin,
  support: mapperSupport,
  sessions: mapperSessions,
  companies: mapperCompanies,
};
