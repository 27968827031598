import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball';

export const graph = css`
  max-width: 54rem;
  margin-left: ${spacing(256)};
`;

export const cards = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  margin-bottom: ${spacing(80)};
`;

export const card = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: hsl(var(--color-white-11));
  padding: ${spacing(24)};
`;

export const count = css`
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-xLarge);
  line-height: var(--line-height-xLarge);
  color: hsl(var(--color-text-1));
  padding: var(--spacing-sml) 0 var(--spacing-xxlrg) 0;
`;
