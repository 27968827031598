import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball';

export const filterTop = css`
  margin: 0 0 ${spacing(32)};
  text-align: right;
`;

export const filterBottom = css`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    margin: 0 ${spacing(15)} 0 0;

    &:last-of-type {
      margin: 0;
    }
  }
`;
