import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball';

export const analytics = css`
  display: grid;
  background: hsl(var(--color-white-11));
  min-width: 300px;
  padding: var(--spacing-xlrg);
  transition: all cubic-bezier(0.39, 0.575, 0.565, 1) 0.3s;
  &:hover,
  &:active {
    box-shadow: 6px 6px 40px rgba(0, 0, 0, 0.07);
  }
`;

export const title = css`
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-micro);
  line-height: var(--line-height-micro);
  letter-spacing: 1px;
  text-transform: uppercase;
  color: hsl(var(--color-text-1));
`;

export const count = css`
  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-xLarge);
  line-height: var(--line-height-xLarge);
  color: hsl(var(--color-text-1));
  padding: var(--spacing-sml) 0 var(--spacing-xxlrg) 0;
`;

export const button = css`
  border-radius: var(--border-radius-full);
  background: hsl(var(--color-white-11));
  border: 1px solid hsl(var(--color-border-7));
  width: ${spacing(32)};
  height: ${spacing(32)};
  justify-self: end;
  text-align: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  &:hover,
  &:active {
    border-color: hsl(var(--color-borderBlack-2));
  }
  & > svg {
    position: relative;
    bottom: 2px;
    left: 1px;
    height: 8px;
    & > path {
      stroke: hsl(var(--color-text-1));
      stroke-width: 2px;
    }
  }
`;
