import { isResponseError, useHttpClient, useToastify } from 'modules/app';
import { useDispatch } from 'react-redux';
import { plansActions } from '../redux';
import { useIntl } from 'gatsby-plugin-intl';
import { HttpError } from 'modules/app/http/client/httpError';

interface HandleErrorProps {
  handleSetErrorMessage?: (errorMessage?: string) => void;
  error?: ApiErrorData;
}

interface FreePlanRequest {
  name: string;
  allowedActiveOpeningCount: number;
}

interface AssignFreePlan {
  targetPlanId: string;
  company: SelectableNameStructure;
  durationInMonths: number;
}

export function useAdminPlans() {
  const dispatch = useDispatch();
  const adminPlansServices = useHttpClient<Plan[]>('/admin/subscription-plans');
  const adminCompaniesServices =
    useHttpClient<AdminResponse<Companies>>('/admin/companies');
  const { toastError, toastSuccess } = useToastify();
  const { formatMessage } = useIntl();

  function handleError({ handleSetErrorMessage, error }: HandleErrorProps) {
    error?.cause === 'plan_name_already_exists'
      ? handleSetErrorMessage?.(
          formatMessage({ id: 'adminPlans.modal.planExist' }),
        )
      : toastError();
  }

  async function getAdminPlans() {
    try {
      const response = await adminPlansServices.get('');
      dispatch(plansActions.setPlans(response.data));
    } catch (e) {
      if (!isResponseError(e)) return;
      toastError();
    }
  }

  async function addRegularPlan(
    data: CreatePlanRequest,
    handleModalClose: VoidFunction,
    handleSetErrorMessage: (errorMessage?: string) => void,
  ) {
    try {
      await adminPlansServices.post('/create', data);
      getAdminPlans();
      toastSuccess('adminPlans.addModal.addSuccess');
      handleModalClose();
    } catch (e) {
      if (!isResponseError(e)) return;
      handleError({ handleSetErrorMessage, error: e.response?.data });
    }
  }

  async function addCustomPlan(
    data: CreatePlanRequest,
    handleModalClose: VoidFunction,
    handleSetErrorMessage: (errorMessage?: string) => void,
  ) {
    try {
      await adminPlansServices.post('/create-custom', data);
      getAdminPlans();
      toastSuccess('adminPlans.addModal.addSuccess');
      handleModalClose();
    } catch (e) {
      if (!isResponseError(e)) return;
      handleError({ handleSetErrorMessage, error: e.response?.data });
    }
  }

  async function addFreePlan(data: FreePlanRequest, callback: VoidFunction) {
    try {
      await adminPlansServices.post<Plan, FreePlanRequest>(
        '/create-free',
        data,
      );
      getAdminPlans();
      callback();
    } catch (e) {
      if (!(e instanceof HttpError)) return;
      if (e.cause === 'plan_name_already_exists') {
        toastError('Plan name already exists');
        return;
      }
      toastError();
    }
  }

  async function assignPlan(data: AssignFreePlan, callback: VoidFunction) {
    try {
      await adminPlansServices.post('/assign-plan', {
        ...data,
        companyId: data.company.value,
      });
      getAdminPlans();
      callback();
      toastSuccess(`Sucessfully added free plan to ${data.company.label}`);
    } catch (e) {
      toastError();
    }
  }

  async function editPlan(
    planId: string,
    data: CreatePlanRequest,
    handleModalClose: VoidFunction,
    handleSetErrorMessage?: (errorMessage?: string) => void,
    isActiveAndCustom?: boolean,
  ) {
    try {
      isActiveAndCustom
        ? await adminPlansServices.put(`/${planId}/edit-companies`, data)
        : await adminPlansServices.put(`/${planId}`, data);
      getAdminPlans();
      toastSuccess('adminPlans.editModal.editSuccess');
      handleModalClose();
    } catch (e) {
      if (!isResponseError(e)) return;
      handleError({ handleSetErrorMessage, error: e.response?.data });
    }
  }

  async function deletePlan(planId: string) {
    try {
      await adminPlansServices.delete(`/${planId}`);
      getAdminPlans();
      toastSuccess('adminPlans.deleteModal.deleteSuccess');
    } catch (e) {
      if (!isResponseError(e)) return;
      handleError({ error: e.response?.data });
    }
  }

  async function activatePlan(planId: string) {
    try {
      await adminPlansServices.post(`/${planId}/activate`);
      getAdminPlans();
      toastSuccess('adminPlans.activateModal.activateSuccess');
    } catch (e) {
      if (!isResponseError(e)) return;
      handleError({ error: e.response?.data });
    }
  }

  async function deactivatePlan(planId: string) {
    try {
      await adminPlansServices.post(`/${planId}/deactivate`);
      getAdminPlans();
      toastSuccess('adminPlans.deactivateModal.deactivateSuccess');
    } catch (e) {
      if (!isResponseError(e)) return;
      handleError({ error: e.response?.data });
    }
  }

  async function searchCompanies(value: string) {
    try {
      const {
        data: { data },
      } = await adminCompaniesServices.get('', {
        params: {
          search: value,
          searchBy: 'name',
          perPage: 10,
        },
      });

      const companies = data
        .filter((c) => c.planStatus !== 'active')
        .map((c) => ({
          value: c.id,
          label: c.name,
        }));
      return companies;
    } catch (e) {
      toastError();
      return;
    }
  }

  return {
    getAdminPlans,
    addRegularPlan,
    addCustomPlan,
    editPlan,
    deletePlan,
    activatePlan,
    deactivatePlan,
    addFreePlan,
    assignPlan,
    searchCompanies,
  };
}
