import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Plans } from './Plans';
import { useAdminPlans } from '../hooks';
import { plansSelector } from '../redux';

export const CustomPlans: React.FC = () => {
  const { getAdminPlans } = useAdminPlans();
  const [filterQuery, setFilterQuery] = useState<FilterQuery>({
    currency: 'EUR',
    isActive: false,
    sortBy: 'createdAt',
  });
  const { error, isLoading, customPlans } = useSelector(
    plansSelector.selectCustomPlans,
  );

  const activePlans = useSelector(
    plansSelector.selectActivePlans(filterQuery, customPlans),
  );

  const inactivePlans = useSelector(
    plansSelector.selectInactivePlans(filterQuery, customPlans),
  );

  const getPlansFn = useCallback(() => {
    getAdminPlans();
  }, []);

  useEffect(getPlansFn, []);

  return (
    <Plans
      filterQuery={filterQuery}
      setFilterQuery={setFilterQuery}
      activePlans={activePlans}
      inactivePlans={inactivePlans}
      error={error}
      isLoading={isLoading}
    />
  );
};
