import React, { useEffect } from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { navigate } from 'gatsby-plugin-intl';
import { useGetUsersActivity } from '../../hooks/useGetUsersActivity';
import * as styles from '../styles/Dashboard.styles';
import { Analytics } from './Analytics';
import { Stats } from './Stats';
import { UsersMonthly } from './UsersMonthly';

export const Dashboard: React.FC<RouteComponentProps> = () => {
  const { overall, isLoading, statistics, getAdminStatistics } =
    useGetUsersActivity();

  useEffect(() => {
    getAdminStatistics();
  }, [getAdminStatistics]);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;
    if (typeof window !== 'undefined') {
      navigate(`/admin/dashboard/${value}`);
    }
  };

  return (
    <article css={styles.dashboard}>
      <motion.div {...FADE_IN_MOVE_Y_REGULAR} role="presentation">
        <Router>
          <Analytics
            path="/"
            isLoading={isLoading}
            totalSessions={statistics?.totalActiveSessions || 0}
            totalAdmins={statistics?.staffWithAdminRole || 0}
            totalSupport={statistics?.staffWithSupportRole || 0}
            totalActive={statistics?.activeUsersInTheLastMonth || 0}
            totalUsers={overall?.totalActiveUsers || 0}
            totalCompanies={overall?.totalNumberOfCompanies || 0}
            totalTalents={overall?.totalNumberOfTalents || 0}
            maxPeak={overall?.maxUsersPeak || 0}
            onClick={handleClick}
          />
          <Stats path={'/dashboard/:urlDataType'} />
          <UsersMonthly
            path={'/dashboard/last-month'}
            totalActive={statistics?.activeUsersInTheLastMonth}
          />
        </Router>
      </motion.div>
    </article>
  );
};
