import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball';

export const dashboard = css`
  position: relative;
  top: 100px;
  display: grid;
  width: calc(100vw - 255px);
  min-width: 650px;
  padding-bottom: ${spacing(24)};
`;
