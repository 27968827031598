import React from 'react';
import { DropdownMenu } from 'modules/app';
import MoreOptionsIcon from 'assets/icons/moreOptions.svg';
import { formatDate } from 'modules/admin/static/formatDate';
import { WarningModal, FormModal, usePlanActions } from 'modules/admin/plans';

import * as styles from '../styles/PlansItem.styles';
import { useMatchUrl, usePlansItem } from '../hooks';
import { AssignToCompanyFormModal } from './AssignToCompanyFormModal';
import { FreePlanForm } from './FreePlanForm';

interface Props {
  plan: Plan;
}

export const PlansItem: React.FC<Props> = ({ plan }) => {
  const {
    isFormModalOpen,
    isWarningModalOpen,
    formModalOptions,
    warningModalOptions,
    isAssignToCompanyModalOpen,
    handleAssignToCompanyModalOpen,
    handleAssignToCompanyModalClose,
    handleModalOpen,
    handleModalClose,
    handleWarningModalOpen,
    handleWarningModalClose,
    handleSetFormModalOptions,
    handleSetWarningModalOptions,
  } = usePlansItem();

  const { matchCustom } = useMatchUrl();
  const planActions = usePlanActions(
    plan,
    handleModalOpen,
    handleSetFormModalOptions,
    handleAssignToCompanyModalOpen,
  );

  const handleActionOnPlanSelect = (planId: string) => {
    const selectedOption = planActions.find((item) => item.id === planId);

    selectedOption?.onSelect(
      plan.id,
      handleWarningModalOpen,
      handleWarningModalClose,
      handleSetWarningModalOptions,
    );
  };

  return (
    <>
      <tr css={styles.plan}>
        <td css={styles.name}>{plan.name}</td>
        <td>
          {plan.price} {plan.currency}
        </td>
        <td>{plan.allowedActiveOpeningCount} openings</td>
        <td>{plan.userFullName}</td>
        <td>{formatDate(plan.createdAt, 'PP')}</td>
        <td>{formatDate(new Date(plan.updatedAt), 'PP')}</td>
        {plan.planType === 'custom' && (
          <td>
            {plan.assignedCompanies.map(
              (company, i) =>
                `${company.name}${
                  plan.assignedCompanies.length === i + 1 ? '' : ','
                }`,
            )}
          </td>
        )}
        <td>
          {plan.activeCompanies.map(
            (company, i) =>
              `${company.name}${
                plan.activeCompanies.length === i + 1 ? '' : ','
              }`,
          )}
        </td>
        <td>
          <DropdownMenu
            onSelect={handleActionOnPlanSelect}
            hideCaret={true}
            placeholder={<MoreOptionsIcon />}
            items={planActions}
            position="bottomRight"
          />
        </td>
      </tr>

      <WarningModal
        isOpen={isWarningModalOpen}
        handleWarningModalClose={handleWarningModalClose}
        plan={plan}
        options={warningModalOptions}
      />

      {isFormModalOpen &&
        (plan.planType === 'free_plan' ? (
          <FreePlanForm
            isOpen={isFormModalOpen}
            handleModalClose={handleModalClose}
            plan={plan}
          />
        ) : (
          <FormModal
            isOpen={isFormModalOpen}
            handleModalClose={handleModalClose}
            plan={plan}
            isEdit={true}
            isCustomPlan={Boolean(matchCustom)}
            options={formModalOptions}
          />
        ))}

      {isAssignToCompanyModalOpen && (
        <AssignToCompanyFormModal
          isOpen={isAssignToCompanyModalOpen}
          handleModalClose={handleAssignToCompanyModalClose}
          plan={plan}
          options={formModalOptions}
        />
      )}
    </>
  );
};
