import React from 'react';
import Seo from 'modules/app/components/Seo';
import { motion } from 'framer-motion';
import { Section, SectionBody } from 'modules/admin/components';
import { PlansContainer, PlansSidebar, useMatchUrl } from 'modules/admin/plans';
import { PlansHeader } from './PlansHeader';

import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import * as gridStyles from 'modules/admin/styles/Grid';
import * as styles from 'modules/admin/styles/AdminOverview.styles';
import { Heading } from 'modules/app';

import { sectionHeadTitle } from 'modules/admin/styles/Section.styles';

interface Props {
  filterQuery: FilterQuery;
  setFilterQuery: React.Dispatch<React.SetStateAction<FilterQuery>>;
  activePlans?: Plan[];
  inactivePlans?: Plan[];
  error?: ApiErrorData;
  isLoading: boolean;
}

export const Plans: React.FC<Props> = ({
  filterQuery,
  setFilterQuery,
  activePlans,
  inactivePlans,
  error,
  isLoading,
}) => {
  const { matchCustom } = useMatchUrl();

  return (
    <>
      <Seo title="Plans" />
      <Section>
        <div css={gridStyles.subSidebarGrid}>
          <PlansSidebar />

          <motion.div
            transition={{ duration: 0.3 }}
            {...FADE_IN_MOVE_Y_REGULAR}
          >
            <PlansHeader
              filterQuery={filterQuery}
              setFilterQuery={setFilterQuery}
            />

            <SectionBody>
              <div css={[sectionHeadTitle, styles.plansTitle]}>
                <Heading as="h1" size="fluidLarge">
                  Active plans
                </Heading>
              </div>
              <table>
                <thead css={styles.tableHead}>
                  <tr>
                    <td width="400">Name</td>
                    <td width="150">Price</td>
                    <td width="150">Openings</td>
                    <td width="200">Created by</td>
                    <td width="200">Created at</td>
                    <td width="200">Active since</td>
                    {matchCustom && <td width="400">Assigned Companies</td>}
                    <td width="400">Active Companies</td>
                    <td width="50">Actions</td>
                  </tr>
                </thead>
                <PlansContainer
                  plans={activePlans}
                  error={error}
                  isLoading={isLoading}
                  noContentMsg="adminPlans.noActivePlansMessage"
                />
              </table>
            </SectionBody>

            {!filterQuery.isActive && (
              <SectionBody>
                <div css={[sectionHeadTitle, styles.plansTitle]}>
                  <Heading as="h1" size="fluidLarge">
                    Inactive plans
                  </Heading>
                </div>
                <table>
                  <thead css={styles.tableHead}>
                    <tr>
                      <td width="400">Name</td>
                      <td width="150">Price</td>
                      <td width="150">Openings</td>
                      <td width="200">Created by</td>
                      <td width="200">Created at</td>
                      <td width="200">Inactive since</td>
                      {matchCustom && <td width="400">Assigned Companies</td>}
                      <td width="400">Active Companies</td>
                      <td width="50">Actions</td>
                    </tr>
                  </thead>

                  <PlansContainer
                    plans={inactivePlans}
                    error={error}
                    isLoading={isLoading}
                    noContentMsg="adminPlans.noInactivePlansMessage"
                  />
                </table>
              </SectionBody>
            )}
          </motion.div>
        </div>
      </Section>
    </>
  );
};
