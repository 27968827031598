import { RouteComponentProps } from '@reach/router';
import React, { useEffect } from 'react';
import { SectionBody, SectionHead } from '../../components';
import { text } from '@prototyp/gatsby-plugin-gumball/utils';

import * as styles from '../styles/UsersMonthly.styles';
import { useIntl } from 'gatsby-plugin-intl';
import { options } from '../constants';
import { Chart, useChartRef } from 'modules/admin/dashboard';
import { useGetUsersActivity } from 'modules/admin/hooks/useGetUsersActivity';
import { getMonthlyGraphData } from 'modules/admin/helpers';

interface Props extends RouteComponentProps<{ urlDataType: string }> {
  totalActive?: number;
}

export const UsersMonthly: React.FC<Props> = ({ totalActive }) => {
  const intl = useIntl();

  const { activity, getActivity } = useGetUsersActivity();
  const data = getMonthlyGraphData(activity);
  const { chartRef } = useChartRef(data?.values);

  useEffect(() => {
    getActivity();
  }, [getActivity]);

  return (
    <div css={styles.graph}>
      <SectionHead title={intl.formatMessage({ id: 'usersMonthly.title' })} />
      <SectionBody>
        <div css={styles.cards} role="presentation">
          <section css={styles.card}>
            <p css={[styles.count, text.align.center]}>{totalActive}</p>
            <p css={[text.color.textLight, text.transform.uppercase]}>
              {intl.formatMessage({ id: 'usersMonthly.totalActive' })}
            </p>
          </section>
          <section css={styles.card}>
            <p css={[styles.count, text.align.center]}>{data?.peakNumber}</p>
            <p css={[text.color.textLight, text.transform.uppercase]}>
              {intl.formatMessage({ id: 'usersMonthly.maxPeak' })}
            </p>
          </section>
          <section css={styles.card}>
            <p css={[styles.count, text.align.center]}>{data?.peakDate}</p>
            <p css={[text.color.textLight, text.transform.uppercase]}>
              {intl.formatMessage({ id: 'usersMonthly.maxPeakDate' })}
            </p>
          </section>
          <section css={styles.card}>
            <p css={[styles.count, text.align.center]}>{data?.noUsersCount}</p>
            <p css={[text.color.textLight, text.transform.uppercase]}>
              {intl.formatMessage({ id: 'usersMonthly.daysWithNoUsers' })}
            </p>
          </section>
        </div>
        {data && (
          <Chart data={data.graph} options={options} chartRef={chartRef} />
        )}
      </SectionBody>
    </div>
  );
};
