import { currencyOption, sortByOptions } from '../constants';

export function isSortBy(value: string | SortBy): value is SortBy {
  return sortByOptions.includes(value as SortBy);
}

export function isCurrency(
  value: string | string[] | SortBy,
): value is Currency {
  return currencyOption.includes(value as Currency);
}
