export interface PlanFormData {
  allowedActiveOpeningCount: string;
  price: string;
  name: string;
  currency: Currency;
  companyIds?: SelectableNameStructure[];
}

export function normalizePlanRequest(data: PlanFormData): CreatePlanRequest {
  return {
    ...data,
    allowedActiveOpeningCount: Number(data.allowedActiveOpeningCount),
    price: Number(data.price),
    companyIds: data.companyIds?.map((c) => c.value),
  };
}
