import React from 'react';
import * as styles from '../styles/UsersCounter.styles';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { SectionHead } from '../components';
import * as sectionStyles from '../styles/Section.styles';
import { useUsersCount } from './hooks';

export const UsersCounter: React.FC = () => {
  const { usersNumber } = useUsersCount();
  return (
    <motion.div transition={{ duration: 0.3 }} {...FADE_IN_MOVE_Y_REGULAR}>
      <div css={sectionStyles.section}>
        <SectionHead title="Awesome Roango Talents"></SectionHead>

        <div css={styles.root}>
          <div css={styles.usersNumber}>
            <p>Total number of users:</p>
            {usersNumber}
          </div>
        </div>
      </div>
    </motion.div>
  );
};
