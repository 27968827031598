import { FormattedMessage } from 'gatsby-plugin-intl';
import { usePlans } from 'modules/admin/plans';
import React, { useMemo } from 'react';

import Delete from 'assets/icons/delete.svg';
import Edit from 'assets/icons/EditBlack.svg';
import Deactivate from 'assets/icons/closeOutline.svg';
import Activate from 'assets/icons/circleCheck.svg';
import AddToCompany from 'assets/icons/person.svg';

import { buttonElement } from '../styles/PlansItem.styles';
import { useMatchUrl } from './useMatchUrl';

interface PlanActionsReturn {
  id: string;
  disabled: boolean;
  onSelect: (
    planId: string,
    handleWarningModalOpen: VoidFunction,
    handleWarningModalClose: VoidFunction,
    handleSetWarningModalOptions: (data: WarningModalOptions) => void,
  ) => void;
  element: React.ReactElement;
}

export const usePlanActions = (
  plan: Plan,
  handleFormModalOpen: VoidFunction,
  handleSetFormModalOptions: (data: FormModalOptions) => void,
  handleAssignToCompanyModalOpen: VoidFunction,
): PlanActionsReturn[] => {
  const { matchRegular, matchBase } = useMatchUrl();
  const {
    handleEdit,
    handleEditCustom,
    handleActivate,
    handleActivateCustom,
    handleDeactivate,
    handleDeactivateCustom,
    handleDelete,
    handleSubsequentlyAddCompany,
    handleActivateCompany,
  } = usePlans();

  const handleEditButtonClick = () => {
    matchRegular || matchBase
      ? handleEdit(handleFormModalOpen, handleSetFormModalOptions)
      : handleEditCustom(handleFormModalOpen, handleSetFormModalOptions);
  };

  const handleAssignToCompany = () => {
    handleSubsequentlyAddCompany(
      handleAssignToCompanyModalOpen,
      handleSetFormModalOptions,
    );
  };

  const handlePlanActivate = () => {
    handleActivateCompany(
      handleAssignToCompanyModalOpen,
      handleSetFormModalOptions,
    );
  };

  const handleActivateButtonClick =
    matchRegular || matchBase ? handleActivate : handleActivateCustom;

  const handleDeactivateButtonClick =
    matchRegular || matchBase ? handleDeactivate : handleDeactivateCustom;

  const planActions = [
    {
      id: 'activate',
      disabled: plan?.isActive,
      onSelect: handleActivateButtonClick,
      element: (
        <span css={buttonElement}>
          <span>
            <Activate />
          </span>
          <span>
            <FormattedMessage id="buttons.activate" />
          </span>
        </span>
      ),
    },
    {
      id: 'deactivate',
      disabled: !plan?.isActive,
      onSelect: handleDeactivateButtonClick,
      element: (
        <span css={buttonElement}>
          <span>
            <Deactivate />
          </span>
          <span>
            <FormattedMessage id="buttons.deactivate" />
          </span>
        </span>
      ),
    },
    {
      id: 'edit',
      disabled: !plan?.editable,
      onSelect: handleEditButtonClick,
      element: (
        <span css={buttonElement}>
          <span>
            <Edit />
          </span>
          <span>
            <FormattedMessage id="buttons.edit" />
          </span>
        </span>
      ),
    },
    {
      id: 'delete',
      disabled: !plan?.editable,
      onSelect: handleDelete,
      element: (
        <span css={buttonElement}>
          <span>
            <Delete />
          </span>
          <span>
            <FormattedMessage id="buttons.delete" />
          </span>
        </span>
      ),
    },
  ];

  const isAssignDisabled = useMemo(() => {
    if (plan.planType === 'custom' && plan.isActive) {
      return false;
    }

    return !plan.editable;
  }, [plan]);

  const planActionsFree = [
    ...planActions,
    {
      id: 'companyActivate',
      disabled: !plan.isActive,
      onSelect: handlePlanActivate,
      element: (
        <span css={buttonElement}>
          <span>
            <AddToCompany />
          </span>
          <span>Activate for company</span>
        </span>
      ),
    },
  ];

  const planActionsCustom = [
    ...planActionsFree,
    {
      id: 'assign',
      disabled: isAssignDisabled,
      onSelect: handleAssignToCompany,
      element: (
        <span css={buttonElement}>
          <span>
            <AddToCompany />
          </span>
          <span>
            <FormattedMessage id="buttons.addToCompany" />
          </span>
        </span>
      ),
    },
  ];

  if (plan.planType === 'free_plan') return planActionsFree;
  if (plan.planType === 'custom') return planActionsCustom;
  return planActions;
};
