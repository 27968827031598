import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball';

export const plan = css`
  border-bottom: 1px solid hsl(var(--color-borderUltraLight-9));
  transition: 0.2s ease;

  &:hover {
    background: hsl(var(--color-backgroundLight-10));
  }

  td {
    padding: ${spacing(16)} 0;
    padding-right: ${spacing(32)};

    :last-of-type {
      padding-right: 0;
    }
  }
`;

export const name = css`
  font-weight: 700;
`;

export const buttonElement = css`
  display: flex;
  align-items: center;
  column-gap: var(--spacing-sml);
`;

export const features = css`
  padding-left: 0;
  margin-right: ${spacing(-40)};
`;

export const footerItem = css`
  display: flex;
  align-items: center;

  p {
    flex-basis: 50%;
  }
`;

export const companyList = css`
  margin: 8px 0;
`;

export const itemLabel = css``;
export const itemValue = css``;
