import { useHttpClient } from 'modules/app';
import { useEffect, useState } from 'react';

export function useUsersCount() {
  const usersService = useHttpClient('/admin');
  const [usersNumber, setUsersCount] = useState(0);

  async function getTalents() {
    try {
      const { data } = await usersService.get<SearchResponse<Users>>('/users');
      setUsersCount(data.total);
    } catch (e) {
      setUsersCount(0);
    }
  }

  useEffect(() => {
    getTalents();
    const clear = setInterval(() => {
      getTalents();
    }, 120000);

    return () => {
      if (clear) clearInterval(clear);
    };
  }, [getTalents]);

  return { usersNumber };
}
