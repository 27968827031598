import React, { useMemo, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { RouteComponentProps } from '@reach/router';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';
import { useCallback } from 'react';
import { useGetData } from 'modules/admin/hooks';

import * as styles from '../styles/General.styles';
import { DashboardList } from './DashboardList';

const Pagination = React.lazy(
  () => import('modules/app/components/pagination/Pagination'),
);

interface Props extends RouteComponentProps<{ urlDataType: string }> {
  path: string;
}
export const Stats: React.FC<Props> = ({ urlDataType = '' }) => {
  const { isLoading, data } = useSelector(
    (state: AppState) => state.adminUsers,
  );
  const { getData } = useGetData(urlDataType);
  const [page, setPage] = useState(1);

  const onPageChange = (data: { selected: number }) => {
    setPage(data.selected + 1);
  };

  const getStats = useCallback(() => {
    getData({ dataType: urlDataType, page: page });
  }, [getData, page, urlDataType]);

  useEffect(getStats, [getStats]);

  const getList = useMemo(() => {
    if (!Boolean(data.users)) {
      return <>No data</>;
    }
    return <DashboardList data={data} dataType={urlDataType} />;
  }, [data, urlDataType]);

  return (
    <div style={{ opacity: isLoading ? 0.5 : 1 }}>
      {getList}
      {data[urlDataType].lastPage && (
        <React.Suspense fallback={null}>
          <motion.div css={styles.pagination}>
            <Pagination
              initialPage={page - 1}
              onPageChange={onPageChange}
              pageCount={data[urlDataType].lastPage}
              forcePage={page - 1}
            />
          </motion.div>
        </React.Suspense>
      )}
    </div>
  );
};
