import { AppState } from 'modules/redux-store';
import {
  handlePlansSortCreatedAt,
  handlePlansSortActiveSince,
} from '../helpers';

const selectPlans = (state: AppState) => state.plans;

const selectCustomPlans = (state: AppState) => {
  const { plans, error, isLoading } = selectPlans(state);

  return {
    error,
    isLoading,
    customPlans: plans?.filter((plan) => plan.planType === 'custom'),
  };
};

const selectRegularPlans = (state: AppState) => {
  const { plans, error, isLoading } = selectPlans(state);

  return {
    error,
    isLoading,
    regularPlans: plans?.filter((plan) => plan.planType === 'public'),
  };
};

const selectFreePlans = (state: AppState) => {
  const { plans, error, isLoading } = selectPlans(state);

  return {
    error,
    isLoading,
    freePlans: plans?.filter((plan) => plan.planType === 'free_plan'),
  };
};

const selectActivePlans = (filterQuery: FilterQuery, plans?: Plan[]) => () => {
  const activePlans = plans?.filter(
    (plan) => plan.isActive && plan.currency === filterQuery.currency,
  );

  if (filterQuery.sortBy === 'createdAt')
    return handlePlansSortCreatedAt(activePlans);

  return handlePlansSortActiveSince(activePlans);
};

const selectInactivePlans =
  (filterQuery: FilterQuery, plans?: Plan[]) => () => {
    const inactivePlans = plans?.filter(
      (plan) => !plan.isActive && plan.currency === filterQuery.currency,
    );

    if (filterQuery.sortBy === 'createdAt')
      return handlePlansSortCreatedAt(inactivePlans);

    return handlePlansSortActiveSince(inactivePlans);
  };

export const plansSelector = {
  selectPlans,
  selectRegularPlans,
  selectCustomPlans,
  selectFreePlans,
  selectActivePlans,
  selectInactivePlans,
};
