import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball';

export const dashboardList = css`
  margin-left: ${spacing(256)};
`;

export const sectionBody = css`
  max-width: 54rem;
`;

export const heading = css`
  text-transform: capitalize;
`;
