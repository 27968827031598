type PlanType = 'base' | 'custom' | 'promo' | 'free';

export const plansHeaders: Record<PlanType, SectionHeader> = {
  base: {
    title: 'Regular plans',
    addLabel: 'Add regular plan',
  },
  custom: {
    title: 'Custom plans',
    addLabel: 'Add custom plan',
  },
  promo: {
    title: 'Promo codes',
    addLabel: 'Add promo code',
  },
  free: {
    title: 'Free plans',
    addLabel: 'Add free plan',
  },
};
