import { margin } from '@prototyp/gatsby-plugin-gumball/utils/margin';
import { useIntl } from 'gatsby-plugin-intl';
import { ActionModal, Button, Heading, InputField } from 'modules/app';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAdminPlans } from '../hooks';

interface FormData {
  name: string;
  allowedActiveOpeningCount: string;
}

interface Props {
  isOpen: boolean;
  handleModalClose: VoidFunction;
  plan?: Plan;
}

export const FreePlanForm: React.FC<Props> = ({
  isOpen,
  handleModalClose,
  plan,
}) => {
  const { formatMessage } = useIntl();
  const { addFreePlan, editPlan } = useAdminPlans();

  const methods = useForm<FormData>({
    defaultValues: {
      name: plan?.name || '',
      allowedActiveOpeningCount: plan
        ? String(plan.allowedActiveOpeningCount)
        : '',
    },
  });
  const { handleSubmit } = methods;

  function onSubmit(data: FormData) {
    if (plan) {
      editPlan(
        plan.id,
        {
          ...plan,
          name: data.name,
          allowedActiveOpeningCount: Number(data.allowedActiveOpeningCount),
        },
        handleModalClose,
      );
      return;
    }

    addFreePlan(
      {
        name: data.name,
        allowedActiveOpeningCount: Number(data.allowedActiveOpeningCount),
      },
      handleModalClose,
    );
  }

  return (
    <ActionModal isOpen={isOpen} setIsOpen={handleModalClose}>
      <header>
        <Heading as="h1" size="xLarge" css={margin.bottom.lrg}>
          {plan ? 'Edit Free Plan' : 'Add free plan'}
        </Heading>
      </header>
      <FormProvider {...methods}>
        <form id="planForm" onSubmit={handleSubmit(onSubmit)}>
          <InputField
            id="name"
            name="name"
            label="adminPlans.modal.inputNameLabel"
            value={plan?.name || ''}
            constraints={{ required: true, maxLength: 100 }}
          />
          <InputField
            id="allowedActiveOpeningCount"
            name="allowedActiveOpeningCount"
            label="adminPlans.modal.inputAllowedActiveOpeningsLabel"
            value={plan ? String(plan.allowedActiveOpeningCount) : ''}
            constraints={{
              required: true,
              maxLength: 10,
              pattern: {
                value: /^[0-9.,]*$/g,
                message: formatMessage({ id: 'shared.errors.number' }),
              },
            }}
          />
        </form>
      </FormProvider>

      <footer>
        <Button
          form="planForm"
          buttonType="primary"
          size="small"
          type="submit"
          textId={plan ? 'buttons.save' : 'buttons.save.plans.free'}
        />

        <Button
          buttonType="ghost"
          size="small"
          type="button"
          textId="buttons.cancel"
          onClick={handleModalClose}
        />
      </footer>
    </ActionModal>
  );
};
