import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball';
import { borderRadius } from 'style/variables';

export const container = css`
  display: grid;
  grid-gap: ${spacing(32)} ${spacing(30)};
  grid-template-columns: repeat(3, 1fr);
`;

export const noContent = css`
  background: hsl(var(--color-white-11));
  padding: ${spacing(40)};
  border-radius: ${borderRadius.regular};
`;
