import { useAdminPlans } from 'modules/admin/plans';

export const usePlans = () => {
  const { deletePlan, activatePlan, deactivatePlan } = useAdminPlans();

  const handleAddRegular = (
    handleModalOpen: VoidFunction,
    handleSetFormModalOptions: (data: FormModalOptions) => void,
  ) => {
    handleModalOpen();
    handleSetFormModalOptions({
      title: 'adminPlans.addModal.title',
      description: 'adminPlans.addModal.description',
      featuresTitle: 'adminPlans.featuresTitle',
    });
  };

  const handleAddCustom = (
    handleModalOpen: VoidFunction,
    handleSetFormModalOptions: (data: FormModalOptions) => void,
  ) => {
    handleModalOpen();
    handleSetFormModalOptions({
      title: 'adminPlans.addModal.title.custom',
      description: 'adminPlans.addModal.description.custom',
      featuresTitle: 'adminPlans.featuresTitle.custom',
    });
  };

  const handleEdit = (
    handleFormModalOpen: VoidFunction,
    handleSetFormModalOptions: (data: FormModalOptions) => void,
  ) => {
    handleFormModalOpen();
    handleSetFormModalOptions({
      title: 'adminPlans.editModal.title',
      description: 'adminPlans.editModal.description',
      featuresTitle: 'adminPlans.editModal.featuresTitle',
    });
  };

  const handleEditCustom = (
    handleFormModalOpen: VoidFunction,
    handleSetFormModalOptions: (data: FormModalOptions) => void,
  ) => {
    handleFormModalOpen();
    handleSetFormModalOptions({
      title: 'adminPlans.editModal.title.custom',
      description: 'adminPlans.editModal.description.custom',
      featuresTitle: 'adminPlans.editModal.featuresTitle',
    });
  };

  const handleActivate = (
    planId: string,
    handleWarningModalOpen: VoidFunction,
    handleWarningModalClose: VoidFunction,
    handleSetWarningModalOptions: (data: WarningModalOptions) => void,
  ) => {
    handleWarningModalOpen();
    handleSetWarningModalOptions({
      title: 'adminPlans.activateModal.title',
      description: 'adminPlans.activateModal.description',
      primaryBtnLabel: 'adminPlans.activateModal.activateButton',
      handleFunction: () => {
        activatePlan(planId);
        handleWarningModalClose();
      },
    });
  };

  const handleActivateCustom = (
    planId: string,
    handleWarningModalOpen: VoidFunction,
    handleWarningModalClose: VoidFunction,
    handleSetWarningModalOptions: (data: WarningModalOptions) => void,
  ) => {
    handleWarningModalOpen();
    handleSetWarningModalOptions({
      title: 'adminPlans.activateModal.title',
      description: 'adminPlans.activateModal.description.custom',
      primaryBtnLabel: 'adminPlans.activateModal.activateButton',
      handleFunction: () => {
        activatePlan(planId);
        handleWarningModalClose();
      },
    });
  };

  const handleDeactivate = (
    planId: string,
    handleWarningModalOpen: VoidFunction,
    handleWarningModalClose: VoidFunction,
    handleSetWarningModalOptions: (data: WarningModalOptions) => void,
  ) => {
    handleWarningModalOpen();
    handleSetWarningModalOptions({
      title: 'adminPlans.deactivateModal.title',
      description: 'adminPlans.deactivateModal.description',
      primaryBtnLabel: 'adminPlans.deactivateModal.deactivateButton',
      handleFunction: () => {
        deactivatePlan(planId);
        handleWarningModalClose();
      },
    });
  };

  const handleDeactivateCustom = (
    planId: string,
    handleWarningModalOpen: VoidFunction,
    handleWarningModalClose: VoidFunction,
    handleSetWarningModalOptions: (data: WarningModalOptions) => void,
  ) => {
    handleWarningModalOpen();
    handleSetWarningModalOptions({
      title: 'adminPlans.deactivateModal.title',
      description: 'adminPlans.deactivateModal.description.custom',
      primaryBtnLabel: 'adminPlans.deactivateModal.deactivateButton',
      handleFunction: () => {
        deactivatePlan(planId);
        handleWarningModalClose();
      },
    });
  };

  const handleDelete = (
    planId: string,
    handleWarningModalOpen: VoidFunction,
    handleWarningModalClose: VoidFunction,
    handleSetWarningModalOptions: (data: WarningModalOptions) => void,
  ) => {
    handleWarningModalOpen();
    handleSetWarningModalOptions({
      title: 'adminPlans.deleteModal.title',
      description: 'adminPlans.deleteModal.description',
      primaryBtnLabel: 'adminPlans.deleteModal.deleteButton',
      handleFunction: () => {
        deletePlan(planId);
        handleWarningModalClose();
      },
    });
  };

  const handleSubsequentlyAddCompany = (
    handleFormModalOpen: VoidFunction,
    handleSetFormModalOptions: (data: FormModalOptions) => void,
  ) => {
    handleFormModalOpen();
    handleSetFormModalOptions({
      title: 'adminPlans.addToCompany.title',
      description: 'adminPlans.addToCompany.description',
    });
  };

  const handleActivateCompany = (
    handleFormModalOpen: VoidFunction,
    handleSetFormModalOptions: (data: FormModalOptions) => void,
  ) => {
    handleFormModalOpen();
    handleSetFormModalOptions({
      title: 'adminPlans.companyActivate.title',
      description: 'adminPlans.companyActivate.description',
      isActivate: true,
    });
  };

  return {
    handleAddRegular,
    handleAddCustom,
    handleEdit,
    handleEditCustom,
    handleActivate,
    handleActivateCustom,
    handleDeactivate,
    handleDeactivateCustom,
    handleDelete,
    handleSubsequentlyAddCompany,
    handleActivateCompany,
  };
};
