import React from 'react';
import { PlansItem } from 'modules/admin/plans';
import { HttpError, Loading } from 'modules/app';
import { FormattedMessage } from 'gatsby-plugin-intl';

import * as plansContainer from '../styles/PlansContainer.styles';

interface Props {
  plans: Plan[] | undefined;
  error: ApiErrorData | undefined;
  isLoading: boolean;
  noContentMsg: string;
}

export const PlansContainer: React.FC<Props> = ({
  plans,
  error,
  isLoading,
  noContentMsg,
}) => {
  if (isLoading) {
    return <Loading isLoading={isLoading} component={null} />;
  }

  return (
    <>
      {error && (
        <tr>
          <td>
            <HttpError error={error} />
          </td>
        </tr>
      )}

      <tbody>
        {plans && plans.length ? (
          plans.map((plan) => <PlansItem key={plan.id} plan={plan} />)
        ) : (
          <tr>
            <td css={plansContainer.noContent}>
              <FormattedMessage id={noContentMsg} />
            </td>
          </tr>
        )}
      </tbody>
    </>
  );
};
