import { useState } from 'react';
import { normalizePlanRequest, PlanFormData } from '../helpers';
import { useAdminPlans } from './useAdminPlans';

interface Props {
  handleModalClose: VoidFunction;
  handleAddPlan?: (
    data: CreatePlanRequest,
    handleModalClose: VoidFunction,
    handleSetErrorMessage: (errorMessage?: string) => void,
  ) => Promise<void>;
  plan?: Plan;
  isEdit?: boolean;
}

export function useFormModal({
  handleModalClose,
  handleAddPlan,
  plan,
  isEdit,
}: Props) {
  const { editPlan } = useAdminPlans();
  const [errorMessage, setErrorMessage] = useState<string>();

  const companyValues = plan?.assignedCompanies.map((company) => ({
    label: company.name,
    value: company.id,
  }));

  const handleSetErrorMessage = (message?: string) => {
    setErrorMessage(message);
  };

  const handleFormSubmit = (data: PlanFormData) => {
    return plan && isEdit
      ? editPlan(
          plan.id,
          normalizePlanRequest(data),
          handleModalClose,
          handleSetErrorMessage,
          plan.isActive && plan.planType === 'custom',
        )
      : handleAddPlan?.(
          normalizePlanRequest(data),
          handleModalClose,
          handleSetErrorMessage,
        );
  };

  const handleFormClose = () => {
    handleModalClose();
    setErrorMessage(undefined);
  };

  return {
    companyValues,
    errorMessage,
    handleFormSubmit,
    handleFormClose,
  };
}
