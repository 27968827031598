import React from 'react';
import { AnalyticsItem } from './AnalyticsItem';
import { RouteComponentProps } from '@reach/router';
import { SectionHead } from '../../components';
import { useSelector } from 'react-redux';
import { AppState } from 'modules/redux-store';

import * as styles from '../styles/Analytics.styles';
import { Skeleton } from 'modules/app';
import { isAdminUser } from 'modules/admin/helpers';

interface Props extends RouteComponentProps {
  isLoading: boolean;
  totalTalents?: number;
  totalUsers?: number;
  totalSessions?: number;
  totalAdmins?: number;
  totalSupport?: number;
  totalCompanies?: number;
  totalActive?: number;
  maxPeak?: number;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Analytics: React.FC<Props> = ({
  isLoading,
  totalTalents,
  totalSessions,
  totalUsers,
  totalAdmins,
  totalSupport,
  totalCompanies,
  totalActive,
  maxPeak,
  onClick,
}) => {
  const { userStaffRole } = useSelector((state: AppState) => state.session);

  const isAdmin = isAdminUser(userStaffRole?.name);

  return (
    <article css={styles.dashboardWrapper}>
      <section css={styles.dashboardSection}>
        <SectionHead title="Overall Statistics" customStyles={styles.heading} />

        {isLoading ? (
          <Skeleton
            count={4}
            containerStyles={styles.grid}
            customStyles={styles.loading}
          />
        ) : (
          <section css={styles.grid}>
            <AnalyticsItem
              title="total number of talents"
              total={totalTalents}
              value="talents"
              onClick={onClick}
            />
            <AnalyticsItem
              title="total number of companies"
              total={totalCompanies}
              value="companies"
              onClick={onClick}
            />
            <AnalyticsItem
              title="total active users"
              total={totalUsers}
              value="users"
              onClick={onClick}
            />
            <AnalyticsItem
              title="max users (peak)"
              total={maxPeak}
              value="sessions"
              onClick={onClick}
            />
          </section>
        )}
      </section>

      {isAdmin && (
        <section css={styles.dashboardSection}>
          <SectionHead title="Admin Statistics" customStyles={styles.heading} />

          {isLoading ? (
            <Skeleton
              count={4}
              containerStyles={styles.grid}
              customStyles={styles.loading}
            />
          ) : (
            <section css={styles.grid}>
              <AnalyticsItem
                title="total active sessions"
                total={totalSessions}
                value="sessions"
                onClick={onClick}
              />
              <AnalyticsItem
                title="active users in the last month"
                total={totalActive}
                value="last-month"
                onClick={onClick}
              />
              <AnalyticsItem
                title="number of staff with admin role"
                total={totalAdmins}
                value="admin"
                onClick={onClick}
              />
              <AnalyticsItem
                title="number of staff with support role"
                total={totalSupport}
                value="support"
                onClick={onClick}
              />
            </section>
          )}
        </section>
      )}
    </article>
  );
};
