import { useState } from 'react';

export function usePlansItem() {
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isAssignToCompanyModalOpen, setIsAssignToCompanyModalOpen] =
    useState(false);
  const [warningModalOptions, setWarningModalOptions] =
    useState<WarningModalOptions>({
      title: '',
      description: '',
      primaryBtnLabel: '',
      handleFunction: () => null,
    });
  const [formModalOptions, setFormModalOptions] = useState<FormModalOptions>({
    title: '',
    description: '',
  });

  const handleModalOpen = () => {
    setIsFormModalOpen(true);
  };

  const handleModalClose = () => {
    setIsFormModalOpen(false);
  };

  const handleAssignToCompanyModalOpen = () => {
    setIsAssignToCompanyModalOpen(true);
  };

  const handleAssignToCompanyModalClose = () => {
    setIsAssignToCompanyModalOpen(false);
  };

  const handleWarningModalOpen = () => {
    setIsWarningModalOpen(true);
  };

  const handleWarningModalClose = () => {
    setIsWarningModalOpen(false);
  };

  const handleSetFormModalOptions = (data: FormModalOptions) => {
    setFormModalOptions(data);
  };

  const handleSetWarningModalOptions = (data: WarningModalOptions) => {
    setWarningModalOptions(data);
  };

  return {
    isWarningModalOpen,
    isFormModalOpen,
    warningModalOptions,
    formModalOptions,
    isAssignToCompanyModalOpen,
    handleAssignToCompanyModalOpen,
    handleAssignToCompanyModalClose,
    handleModalOpen,
    handleModalClose,
    handleWarningModalOpen,
    handleWarningModalClose,
    handleSetFormModalOptions,
    handleSetWarningModalOptions,
  };
}
