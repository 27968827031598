import React from 'react';
import { AdminTable, NoData, SectionBody, SectionHead } from '../../components';
import { motion } from 'framer-motion';
import { FADE_IN_MOVE_Y_REGULAR } from 'style/animations.config';
import { mappers } from '../../static/mappers';

import * as styles from '../styles/DashboardList.styles';
import { DashboardItem } from './DashboardItem';

interface Props {
  data: {
    talents: AdminResponse<Users>;
    users: AdminResponse<Users>;
    sessions: AdminResponse<Sessions>;
  };
  dataType: string;
}

export const DashboardList: React.FC<Props> = ({ data, dataType }) => {
  const header = [
    { id: 'user', title: 'user', width: '200px' },
    dataType !== 'companies'
      ? { id: 'role', title: 'role', width: '200px' }
      : null,
    {
      id: 'sessionStarted',
      title: `${dataType === 'companies' ? 'updated' : 'created at'}`,
      width: '150px',
    },
  ];

  if (!dataType) {
    return null;
  }

  return (
    <article css={styles.dashboardList}>
      <SectionHead title={dataType} customStyles={styles.heading} />

      <motion.div {...FADE_IN_MOVE_Y_REGULAR} role="presentation">
        <SectionBody customStyles={styles.sectionBody}>
          {Boolean(data[dataType].data.length) && (
            <AdminTable headers={header}>
              {data[dataType].data.map((item: Users & Sessions & Companies) => (
                <DashboardItem key={item.id} item={mappers[dataType](item)} />
              ))}
            </AdminTable>
          )}
          {!Boolean(data[dataType].data.length) && <NoData dataType="users" />}
        </SectionBody>
      </motion.div>
    </article>
  );
};
