import React from 'react';
import * as styles from '../../styles/Table.styles';
import { UserCard } from '../../components';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';
import { formatDate } from 'modules/admin/static';

interface Props {
  item: (Sessions | Companies | Users) & RenamedProps;
}

export const DashboardItem: React.FC<Props> = ({
  item: { name, time, info, role, picture, lastName, type },
}) => {
  return (
    <tr css={styles.row}>
      <td css={styles.data}>
        <UserCard
          profilePicture={picture?.small}
          name={name}
          lastName={lastName}
          noLink={true}
          info={info}
        />
      </td>
      {type !== 'companies' && (
        <td>
          <strong>{role ? role : 'User'}</strong>
        </td>
      )}
      <td>
        <div>
          <div css={font.weight.bold}>
            {formatDate(time, 'LLL do')}, {formatDate(time, 'HH:mm')}
          </div>
        </div>
      </td>
    </tr>
  );
};
