import { useMemo, useState } from 'react';
import { plansHeaders } from '../constants';
import { useAdminPlans } from './useAdminPlans';
import { useMatchUrl } from './useMatchUrl';
import { usePlans } from './usePlans';

export function usePlansHeader() {
  const { matchBase, matchRegular, matchCustom, matchFree } = useMatchUrl();
  const { handleAddRegular, handleAddCustom } = usePlans();
  const { addCustomPlan, addRegularPlan } = useAdminPlans();

  const [isOpen, setIsOpen] = useState(false);
  const [formModalOptions, setFormModalOptions] = useState<FormModalOptions>({
    title: '',
    description: '',
  });

  const handleAddPlan =
    matchRegular || matchBase ? addRegularPlan : addCustomPlan;

  const data = useMemo(() => {
    if (matchRegular || matchBase) return plansHeaders.base;
    if (matchCustom) return plansHeaders.custom;
    if (matchFree) return plansHeaders.free;

    return plansHeaders.base;
  }, [matchRegular, matchBase, matchCustom]);

  const handleModalOpen = () => {
    setIsOpen(true);
  };

  const handleModalClose = () => {
    setIsOpen(false);
  };

  const handleAddButtonClick = () => {
    matchRegular || matchBase
      ? handleAddRegular(handleModalOpen, setFormModalOptions)
      : handleAddCustom(handleModalOpen, setFormModalOptions);
  };

  return {
    data,
    isOpen,
    formModalOptions,
    handleAddPlan,
    handleModalOpen,
    handleModalClose,
    handleAddButtonClick,
  };
}
