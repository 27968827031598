import React from 'react';

import DirectionRight from 'assets/icons/directionRight.svg';

import * as styles from '../styles/AnalyticsItem.styles';

interface Props {
  title?: string;
  total?: number;
  value?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const AnalyticsItem: React.FC<Props> = ({
  title,
  total,
  value,
  onClick,
}) => {
  return (
    <section css={styles.analytics}>
      <h3 css={styles.title}>{title}</h3>
      <p css={styles.count}>{total}</p>
      <button css={styles.button} value={value} onClick={onClick}>
        <DirectionRight />
      </button>
    </section>
  );
};
