import { useHttpClient, useToastify } from 'modules/app';
import { useCallback, useState } from 'react';

interface AdminStatistics {
  activeUsersInTheLastMonth: number;
  staffWithAdminRole: number;
  staffWithSupportRole: number;
  totalActiveSessions: number;
}

interface AdminOverallStatistics {
  maxUsersPeak: number;
  totalActiveUsers: number;
  totalNumberOfCompanies: number;
  totalNumberOfTalents: number;
}

export const useGetUsersActivity = () => {
  const http = useHttpClient('/admin');
  const { toastError } = useToastify();
  const [isLoading, setIsLoading] = useState(false);
  const [statistics, setStatistics] = useState<AdminStatistics>();
  const [overall, setOverall] = useState<AdminOverallStatistics>();
  const [activity, setActivity] = useState<UsersActivityResponse[]>();

  const getAdminStatistics = useCallback(async () => {
    setIsLoading(true);
    try {
      const [statistics, overall] = await Promise.all([
        http.get<AdminStatistics>('/statistics'),
        http.get<AdminOverallStatistics>('/statistics-overall'),
      ]);
      setStatistics(statistics.data);
      setOverall(overall.data);
    } catch {
      toastError();
    } finally {
      setIsLoading(false);
    }
  }, [http]);

  const getActivity = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await http.get<UsersActivityResponse[]>(
        '/users/activity',
      );
      setActivity(data);
    } catch {
      toastError();
    } finally {
      setIsLoading(false);
    }
  }, [http]);

  return {
    overall,
    activity,
    isLoading,
    statistics,
    getActivity,
    getAdminStatistics,
  };
};
