import { font } from '@prototyp/gatsby-plugin-gumball/utils';
import { AdminCheckBox, SectionHead } from 'modules/admin/components';
import { Button, DropdownMenu } from 'modules/app';
import React, { useEffect } from 'react';
import { generateDropdownOptions } from 'modules/admin/static';
import { FormattedMessage } from 'gatsby-plugin-intl';
import {
  FormModal,
  useMatchUrl,
  currencyOption,
  isCurrency,
  isSortBy,
  sortByOptions,
} from 'modules/admin/plans';

import PlusIcon from 'assets/icons/plus.svg';

import * as plansHeader from '../styles/PlansHeader.styles';
import { usePlansHeader } from '../hooks';
import { useGetAdminCompanies } from 'modules/admin/hooks';
import { FreePlanForm } from './FreePlanForm';

interface Props {
  filterQuery: FilterQuery;
  setFilterQuery: React.Dispatch<React.SetStateAction<FilterQuery>>;
}

export const PlansHeader: React.FC<Props> = ({
  filterQuery,
  setFilterQuery,
}) => {
  const { matchRegular, matchFree, matchCustom } = useMatchUrl();
  const { getAllAdminCompanies } = useGetAdminCompanies();

  const {
    isOpen,
    data,
    formModalOptions,
    handleAddButtonClick,
    handleAddPlan,
    handleModalClose,
  } = usePlansHeader();

  const onSetCurrency = (id: string) => {
    setFilterQuery({
      ...filterQuery,
      currency: isCurrency(id) ? id : filterQuery?.currency,
    });
  };

  const onHideInactive = () => {
    setFilterQuery({
      ...filterQuery,
      isActive: !filterQuery.isActive,
    });
  };

  const onSortByCreateDate = (id: string) => {
    setFilterQuery({
      ...filterQuery,
      sortBy: isSortBy(id) ? id : filterQuery.sortBy,
    });
  };

  useEffect(() => {
    if (matchRegular) return;
    getAllAdminCompanies({});
  }, [matchRegular]);

  return (
    <>
      <SectionHead title={data?.title}>
        <div css={plansHeader.filterTop} role="presentation">
          <Button icon={<PlusIcon />} onClick={handleAddButtonClick}>
            {data?.addLabel}
          </Button>
        </div>

        <div css={plansHeader.filterBottom} role="presentation">
          <DropdownMenu
            onSelect={onSetCurrency}
            placeholder={
              <span>
                currency: <strong>{filterQuery.currency}</strong>
              </span>
            }
            hideCaret={false}
            items={generateDropdownOptions(
              currencyOption,
              filterQuery.currency,
            )}
            position="bottomRight"
            customButtonStyles={font.weight.regular}
          />

          <DropdownMenu
            onSelect={onSortByCreateDate}
            placeholder={
              <span>
                sortBy: <strong>{filterQuery.sortBy}</strong>
              </span>
            }
            hideCaret={false}
            items={generateDropdownOptions(sortByOptions, filterQuery.sortBy)}
            position="bottomRight"
            customButtonStyles={font.weight.regular}
          />

          <AdminCheckBox
            name="isActive"
            onChange={onHideInactive}
            checked={filterQuery.isActive}
            isSmall={true}
          >
            <FormattedMessage id="adminPlans.checkboxLabel" />
          </AdminCheckBox>
        </div>
      </SectionHead>

      {isOpen &&
        (matchFree ? (
          <FreePlanForm isOpen={isOpen} handleModalClose={handleModalClose} />
        ) : (
          <FormModal
            isOpen={isOpen}
            handleModalClose={handleModalClose}
            isEdit={false}
            isCustomPlan={Boolean(matchCustom)}
            options={formModalOptions}
            handleAddPlan={handleAddPlan}
          />
        ))}
    </>
  );
};
