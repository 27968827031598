import { createAction } from 'modules/redux-store';

export const plansActions = {
  setPlans: (payload?: Plan[]) => createAction('plans/SET_PLANS', payload),
  setPlansFilters: (payload: FilterQuery) =>
    createAction('plans/SET_FILTERS', payload),
  loading: () => createAction('plans/LOADING'),
  error: (payload: ApiErrorData) => createAction('plans/ERROR', payload),

  reset: () => createAction('plans/PIPELINES_RESET'),
};
