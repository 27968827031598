import { ChartData } from 'chart.js';
import React from 'react';
import { Line } from 'react-chartjs-2';
import { ChartJSOrUndefined } from 'react-chartjs-2/dist/types';

import * as styles from '../styles/Chart.styles';

interface Props {
  data: ChartData<'line', number[] | undefined, string>;
  options: Record<string, unknown>;
  chartRef:
    | React.MutableRefObject<
        ChartJSOrUndefined<'line', number[] | undefined, string>
      >
    | undefined;
}

export const Chart: React.FC<Props> = ({ data, options, chartRef }) => {
  return (
    <Line css={styles.canvas} data={data} options={options} ref={chartRef} />
  );
};
