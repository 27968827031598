import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball';
import { font } from '@prototyp/gatsby-plugin-gumball/utils';

export const root = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const usersNumber = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 900px;
  height: 450px;
  padding: ${spacing(64)};
  background-color: white;
  ${font.size.huge};

  & > p {
    margin-right: ${spacing(32)};
  }
`;
