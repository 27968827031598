import { useMatch } from '@reach/router';

export const useMatchUrl = () => {
  const matchBase = useMatch('/:lang/admin/plans');
  const matchRegular = useMatch('/:lang/admin/plans/regular');
  const matchCustom = useMatch('/:lang/admin/plans/custom');
  const matchPromo = useMatch('/:lang/admin/plans/promo');
  const matchFree = useMatch('/:lang/admin/plans/free');

  return {
    matchBase,
    matchRegular,
    matchCustom,
    matchPromo,
    matchFree,
  };
};
