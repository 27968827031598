export const options = {
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      display: true,
      ticks: {
        padding: 15,
        color: '#A5AEB6',
      },
      grid: {
        drawOnChartArea: false,
        drawBorder: false,
      },
      title: {
        display: false,
        text: 'Month',
      },
    },
    y: {
      display: false,
      title: {
        display: true,
        text: 'Value',
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
  },
};
