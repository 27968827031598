import React from 'react';
import { PageProps } from 'gatsby';
import { Router } from '@reach/router';
import { navigate } from 'gatsby-plugin-intl';
import { ProtectedRouter } from 'modules/app';
import { AppState } from 'modules/redux-store';
import Seo from 'modules/app/components/Seo';
import {
  AdminLayout,
  ActivityLog,
  Overview as StaffOverview,
  CompaniesOverview,
  CompanyDetails,
  Users,
} from 'modules/admin/components';
import { useSelector } from 'react-redux';
import { Dashboard } from 'modules/admin/dashboard';
import { Invoices } from 'modules/admin/invoices';
import { Applicants } from 'modules/admin/applicants';
import { UsersCounter } from 'modules/admin/users-counter';
import { BillingActivity } from 'modules/admin/billing-activity';
import { CustomPlans, FreePlans, RegularPlans } from 'modules/admin/plans';

const Admin: React.FC<PageProps> = () => {
  const { userStaffRole, isLoading } = useSelector(
    (state: AppState) => state.session,
  );

  if (isLoading || typeof window === 'undefined') {
    return null;
  }

  if (!['Admin', 'Support', 'SuperAdmin'].includes(userStaffRole?.name ?? '')) {
    if (typeof window !== 'undefined') {
      navigate('/login');
    }
    return null;
  }

  return (
    <>
      <Seo title="Dashboard" />
      <AdminLayout>
        <Router basepath="/:lang/admin">
          <ProtectedRouter
            path={`/dashboard/*`}
            Component={Dashboard}
            default
          />
          <ProtectedRouter
            path={`/companies/*`}
            Component={CompaniesOverview}
          />
          <ProtectedRouter
            path={`/companies/:companyId`}
            Component={CompanyDetails}
          />
          <ProtectedRouter path={`/staff/*`} Component={StaffOverview} />
          <ProtectedRouter path={`/users/*`} Component={Users} />
          <ProtectedRouter path={`/activity/*`} Component={ActivityLog} />
          <ProtectedRouter path={`/plans/regular`} Component={RegularPlans} />
          <ProtectedRouter path={`/plans/custom`} Component={CustomPlans} />
          <ProtectedRouter path={`/plans/free`} Component={FreePlans} />
          <ProtectedRouter path={`/invoices/*`} Component={Invoices} />
          <ProtectedRouter path={`/applicants/*`} Component={Applicants} />
          <ProtectedRouter path={`/talents/*`} Component={UsersCounter} />
          <ProtectedRouter
            path={`/billing-activity/*`}
            Component={BillingActivity}
          />
        </Router>
      </AdminLayout>
    </>
  );
};

export default Admin;
